import {Link} from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet'

import Layout from '../components/layout';

export default function Page404(props) {
  return (
    <Layout language='sl' nodeData={{fields_sl: {slug: '/'}, fields_en: {slug: '/en'}}}>
      <Helmet>
        <title>Urška Grošelj - Page Not Found</title>
      </Helmet>
      <div className='main-content'>
        <h1>Ooops&hellip;</h1>
        <p>
          Requested page does not exist. It looks like you followed a broken link.<br/>
          Please continue <Link to='/en'>to the homepage</Link>.
        </p>
        <p>
          Zahtevana stran ne obstaja. Izgleda, da ste sledili napačni povezavi.<br/>
          Prosim, nadaljujte <Link to='/'>na domačo stran</Link>.
        </p>
      </div>
    </Layout>
  );
}
